import { CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Treemap, Tooltip, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, Brush } from "recharts";
import { CHART_HEIGHT, CHART_WIDTH } from "../../constants/style";
import useStores from "../../hooks/use-stores";
import IESAdvanceStackedBar from "../charts/iesAdvancedStackedBar";
import IESBar from "../charts/iesBar";
import IESTreeMap from "../charts/iesTreeMap";

const data_tm = [
  {
    name: "Ms Sharp",
    num: 12
  },
  {
    name: "Mr Tomas",
    num: 18
  },
  {
    name: "Mrs Williams",
    num: 13
  },
  {
    name: "Mr Crisp",
    num: 16
  },
  {
    name: "Miss White",
    num: 10
  },
  {
    name: "Mr Beed",
    num: 20
  },
  {
    name: "Mr Bean",
    num: 25
  },
];
const COLORS = [
  "#05b8aa",
  "#dfbfbf",
  "#a76999",
  "#f4d25a",
  "#badbee",
  "#52354c",
  "#114514"
];

const data_sbc = [
  {
    name: 'Regionous Education',
    "Miss White": 10,
    "Mr Beed": 15,
    "Mrs Williams": 29,
  },
  {
    name: 'The Arts',
    "Miss White": 24,
    "Mr Beed": 13,
    "Mrs Williams": 12,
  },
  {
    name: 'Research and Investigation',
    "Mr Beed": 20,
    "Miss White": 11,
    "Mrs Williams": 12,
  },
  {
    name: 'Humanities',
    "Mr Beed": 18,
    "Miss White": 3,
    "Mrs Williams": 10,
  },
  {
    name: 'Science',
    "Mr Beed": 15,
    "Ms Sharp": 4,
    "Mr Tomas": 11,
  },
  {
    name: 'Languages',
    "Mr Beed": 15,
    "Mrs Williams": 3,
    "Ms Sharp": 2,
    "Mr Tomas": 6
  },
  {
    name: 'Health and Physical Education',
    "Ms Sharp": 3,
    "Mrs Williams": 10,
    "Mr Beed": 2,
  },
];

const data_uts = [
  {
    name: 'Abbney Copper',
    subject: 'The Arts',
    task: 'Design Task: lines',
    type: 'Non-Submission',
    timestamp: '5/7/2021 23:59:59 PM'
  },
  {
    name: 'Abbney Copper',
    subject: 'The Arts',
    task: 'Design Task: lines',
    type: 'Non-Submission',
    timestamp: '5/7/2021 23:59:59 PM'
  },
  {
    name: 'Abbney Copper',
    subject: 'The Arts',
    task: 'Design Task: lines',
    type: 'Non-Submission',
    timestamp: '5/7/2021 23:59:59 PM'
  },
  {
    name: 'Abbney Copper',
    subject: 'The Arts',
    task: 'Design Task: lines',
    type: 'Non-Submission',
    timestamp: '5/7/2021 23:59:59 PM'
  },
  {
    name: 'Abbney Copper',
    subject: 'The Arts',
    task: 'Design Task: lines',
    type: 'Non-Submission',
    timestamp: '5/7/2021 23:59:59 PM'
  },
];

const CustomizedContent = (props: any) => {
  const { root, depth, x, y, width, height, index, colors, name, value, num } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill:
            depth < 2
              ? colors[index % colors.length]
              : "none",
          stroke: "#fff",
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {depth === 1 ? (
        <text
          x={x + 10}
          y={y + height - 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={14}
        >
          {num}
        </text>
      ) : null}
      {depth === 1 ? (
        <text x={x + 4} y={y + 18} fill="#fff" fontSize='12px' fillOpacity={0.9}>
          {name}
        </text>
      ) : null}
    </g>
  );
};

const SubmissionAnalysis = observer(() => {
  const { academicStore } = useStores();
  const [nonSubmissionByTeacherList, setNonSubmissionByTeacherList] = useState<any>([]);
  const [nonSubmissionByDomainLabel, setNonSubmissionByDomainLabel] = useState<any>([]);
  const [nonSubmissionByDomainList, setNonSubmissionByDomainList] = useState<any>([]);
  const [countNonSubmissionLabel, setCountNonSubmissionLabel] = useState<any>([]);
  const [countNonSubmissionValue, setCountNonSubmissionValue] = useState<any>([]);
  const [legend, setLegend] = useState<any>([]);
  const [nonSubmissionReasonCount, setNonSubmissionReasonCount] = useState({
    names: ['Abney Cooper', 'Poe Losp', 'Martin Foley', 'Joseph Dunstan', 'Jon Pierik', 'Nathanael Cooper', 'Mria Frost', 'Equal Opportunity', 'Marc Murphy', 'Elizabeth Knight'],
    nums: [1, 2, 2, 2, 3, 4, 4, 6, 7, 7]
  });

  useEffect(() => {
    if (academicStore.nonSubmissionByTeacherSAList.length > 0) {
      var temp = [];
      academicStore.nonSubmissionByTeacherSAList.filter(item => item.amount > 0).map((item) => {
        temp.push({
          name: item.label,
          num: item.amount
        });
      })
      // Only show first 10 records;
      temp.splice(10);
      console.log(temp);

      setNonSubmissionByTeacherList(temp);
    }
  }, [academicStore.nonSubmissionByTeacherSAList]);

  useEffect(() => {
    if (academicStore.nonSubmissionByDomainTeacherSAList.length > 0) {
      var value = [];
      var label = [];
      var index = 0;
      academicStore.nonSubmissionByDomainTeacherSAList.map((record) => {
        label.push(record.category);
        record.detailData.map((data) => {
          var tempData = [];
          for (var i = 0; i < academicStore.nonSubmissionByDomainTeacherSAList.length; i++) {
            tempData.push(0);
          }
          tempData[index] = data.amount;

          value.push({
            name: data.label,
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: tempData
          })
        })
        index++;
      })
      setNonSubmissionByDomainList(value);
      setNonSubmissionByDomainLabel(label);
    }
  }, [academicStore.nonSubmissionByDomainTeacherSAList]);

  useEffect(() => {
    if (academicStore.countNonSubmissionSAList.length > 0) {
      var tempLabel = [];
      var tempAmount = [];
      academicStore.countNonSubmissionSAList.map((record) => {
        tempLabel.push(record.label);
        tempAmount.push(record.amount);
      })

      setCountNonSubmissionLabel(tempLabel);
      setCountNonSubmissionValue(tempAmount);
    }
  }, [academicStore.countNonSubmissionSAList])

  return (
    <div>
      <div className="mx-2 mt-2 pl-4 bg-barPrimary text-white grid grid-cols-2">
        <div className="py-2">Submission Analysis</div>
      </div>
      <div className="mx-2 mb-2 pl-4 py-2 bg-white">
        <div className="my-2 grid grid-cols-2">
          <div>
            <p className="w-full text-center my-4 text-blue-600">
              Non Submission by Class Teacher
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <Treemap
                  className="mx-auto w-full"
                  width={400}
                  height={400}
                  data={nonSubmissionByTeacherList}
                  dataKey="num"
                  content={<CustomizedContent colors={COLORS} />}
                >
                  <Tooltip />
                </Treemap>
            }
          </div>
          <div className="px-4">
            <p className="w-full text-center mt-4 text-blue-600">
              Tasks Listed in Simon/Pam as unsubmitted with Due dates in selected range by subjects/domain (group by class teacher)
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESAdvanceStackedBar
                  dataAndSetting={{
                    data: {
                      names: countNonSubmissionLabel.length > 0 ? countNonSubmissionLabel : ["No Result"],
                      values: countNonSubmissionValue.length > 0 ? countNonSubmissionValue : [0]
                    },
                    height: '100%',
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                  label={nonSubmissionByDomainLabel}
                  source={nonSubmissionByDomainList}
                />
            }
          </div>

        </div>
      </div>

      <div className="mx-2 mt-2 pl-4 bg-white text-white grid grid-cols-2">
        <div className="py-2"></div>
        <div className="text-right"><button className="px-4 py-2 bg-advancedSearchButton">Advance Search</button></div>
      </div>

      <div className="mx-2 mb-2 pl-4 py-2 bg-white">

        <div className="my-2 grid grid-cols-2 gap-2">
          <div>
            <p className="w-full text-center my-4 text-blue-600">
              Unsubmitted Task by Student
            </p>
            <div className="overflow-auto p-4" style={{ maxHeight: 400 }}>
              <table className="w-full text-xs">
                {
                  academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                    :
                    academicStore.unsubmittedTaskSAList.length > 0 ?
                      <table>
                        <tr>
                          <td className="pb-2 font-bold">Name</td>
                          <td className="pb-2 font-bold">Domain</td>
                          <td className="pb-2 font-bold">Task</td>
                          <td className="pb-2 font-bold">Reason</td>
                          <td className="pb-2 font-bold">Due Date</td>
                        </tr>
                        {
                          academicStore.unsubmittedTaskSAList.map((record) => (
                            <tr className="border-t">
                              <td className="pr-2 text-xs">{record.name}</td>
                              <td className="pr-2 text-xs">{record.domain}</td>
                              <td className="pr-2 text-xs">{record.taskTitle}</td>
                              <td className="pr-2 text-xs">{record.reason}</td>
                              <td className="pr-2 text-xs">{record.dueDate.substring(0, 10)}</td>
                            </tr>
                          ))
                        }
                      </table>
                      :
                      <div className="w-full text-center text-lg text-barPrimary">No Result Found</div>
                }
              </table>
            </div>

          </div>
          <div className="px-4">
            <p className="w-full text-center mt-4 text-blue-600">
              Count of Non-Submission Reason by Student
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: countNonSubmissionLabel.length > 0 ? countNonSubmissionLabel : ["No Result"],
                      values: countNonSubmissionValue.length > 0 ? countNonSubmissionValue : [0]
                    },
                    height: '100%',
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }
          </div>

        </div>
      </div>

    </div>

  )
})

export default SubmissionAnalysis;