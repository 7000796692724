import { Button, CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { CHART_BAR_COLOR, CHART_BAR_WIDTH, CHART_HEIGHT, CHART_WIDTH } from "../../constants/style";
import useStores from "../../hooks/use-stores";
import IESAdvanceClusteredBar from "../charts/iesAdvanceClusteredBar";

import IESBar from "../charts/iesBar";

const OrderOfStudents = observer(() => {
  const { academicStore, filterStore } = useStores();
  const [year, setYear] = useState("");
  const [byPercentageLabel, setByPercentageLabel] = useState<any>([]);
  const [byPercentageValue, setByPercentageValue] = useState<any>([]);
  const [topTenLabel, setTopTenLabel] = useState<any>([]);
  const [topTenValue, setTopTenValue] = useState<any>([]);
  const [distributionValue, setDistributionValue] = useState<any>([]);
  const [scoreInfo, setScoreInfo] = useState<any>([]);

  useEffect(() => {
    setTopTenLabel([]);
  }, [])

  useEffect(() => {
    if (academicStore.averagePercentageByStudent.length > 0) {
      var tempLabel = [];
      var tempAmount = [];
      academicStore.averagePercentageByStudent.map((record) => {
        tempLabel.push(record.name);
        tempAmount.push(record.averagePercentage);
      })
      
      setByPercentageLabel(tempLabel.reverse());
      setByPercentageValue(tempAmount.reverse());
    }
  }, [academicStore.averagePercentageByStudent]);

  const onApplyAdvanceSearch = (year, semester, yearLevel, classCodeList, domainList) => {
    setYear(year);
    var finalClassList;
    if (classCodeList.length == 1 && classCodeList[0] == "All") {
      var tempList = [];
      for (var c of filterStore.classList) {
        tempList.push(c.homeroomCode);
      }
      finalClassList = tempList;
    }
    else
      finalClassList = classCodeList;

    var finalDomainList = [];
    if (domainList.length == 1 && domainList[0] == "All") {
      for (var dl of filterStore.domainList) {
        finalDomainList.push(dl.domainDescription);
      }
    } else {
      for (var d of domainList) {
        for (var dl of filterStore.domainList) {
          if (+d == dl.id) {
            finalDomainList.push(dl.domainDescription);
            break;
          }
        }
      }
    }

    if (!semester && semester == 0) return;
    let reqData =
    {
      semesterId: semester,
      homeRoomList: finalClassList,
      domainDescriptionList: finalDomainList
    };

    console.log(reqData);

    academicStore.getMarkDistributionByHomeClass(reqData);
    academicStore.getAveragePercentageByStudent(reqData);
  }

  useEffect(() => {
    if (academicStore.averagePercentageByStudent.length > 0) {
      var tempLabel = [];
      var tempValue = [];
      var count = 0;
      for (var record of academicStore.averagePercentageByStudent) {
        if (count < 10) {
          tempLabel.push(record.name);
          tempValue.push(record.averagePercentage);
        } else {
          break;
        }
        count++;
      }
      console.log(tempLabel, tempValue);

      setTopTenLabel(tempLabel);
      setTopTenValue(tempValue);
    }
  }, [academicStore.averagePercentageByStudent]);

  useEffect(() => {

    if (academicStore.markDistributionByHomeClass.length > 0) {
      // let tempScoreInfo = [];
      var legend = [["product"]];
      for (var record of academicStore.markDistributionByHomeClass[0].data) {
        legend[0].push(record.label);
      }

      academicStore.markDistributionByHomeClass.map((record) => {
        var temp = [record.homeClass];
        // tempScoreInfo.totalStudent += record.totalStudent;

        // if (tempScoreInfo.highestScore < record.highestScore)
        //   tempScoreInfo.highestScore = record.highestScore;

        // if (tempScoreInfo.lowestScore > record.lowestScore)
        //   tempScoreInfo.lowestScore = record.lowestScore;

        // tempScoreInfo.averageScore += record.averageScore;

        record.data.map((data) => {
          temp.push(+data.amountString);
        })
        legend.push(temp);
      });
      console.log(legend);

      setDistributionValue(legend);
    }
  }, [academicStore.markDistributionByHomeClass]);

  return (
    <>
      <div className="mx-2 mt-2 pl-4 bg-barPrimary text-white grid grid-cols-2">
        <div className="py-2">Order of Students in Year Level</div>
      </div>
      <div className="mx-2 mb-2 pl-4 py-2 bg-white grid grid-cols-2">
        <div>
          <div>
            <p className="w-full text-center p-2 text-blue-600">
              Average of Percentage and First Task Title by Name
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: byPercentageLabel.length > 0 ? byPercentageLabel : ["No Result"],
                      values: byPercentageValue.length > 0 ? byPercentageValue : [0]
                    },
                    height: '450px',
                    width: '400px',
                    initialShow: 200
                  }}
                />
            }
          </div>
        </div>
        <div className="px-4 overflow-auto" style={{ maxHeight: 500 }}>
          <p className="w-full text-center pt-2 text-blue-600">
            Average of Percentage Table
          </p>
          {
            academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              : academicStore.averagePercentageByStudent.length > 0 ?
                <table className="mr-2 mt-6 w-full p-1">
                  <tr className="border-t border-b">
                    <th className="text-left">UID</th>
                    {/* <th className="">Preferred</th> */}
                    <th className="text-left">Name</th>
                    <th className="text-right">Average of Percentage</th>
                    {/* <th className="">YearDescription</th> */}
                  </tr>
                  {academicStore.averagePercentageByStudent.map((record) => (
                    <tr className="border-t border-b">
                      <td className="">{record.uid}</td>
                      {/* <td className="">{record.preferred}</td> */}
                      <td className="">{record.name}</td>
                      <td className="text-right">
                        {
                          record.averagePercentage < 1 ?
                            record.averagePercentage.toFixed(3)
                            : record.averagePercentage
                        }
                      </td>
                      {/* <td className=" text-right">{record.yearDescription}</td> */}
                    </tr>
                  ))}
                </table>
                :
                <div className="w-full text-center text-lg py-8 text-barPrimary">No Result Found</div>
          }
        </div>
        {/*<div className="mt-6 mb-2 grid grid-cols-2">

           <div>
            <p className="mx-auto text-xs text-blue-600" align="center">
              Average of Percentage by Student Name
            </p>
            <IESVBar dataAndSetting={{ data: { names: averagePercentageList.names, values: averagePercentageList.nums }, height: '500px', width: '400px', initialShow: 10 }} />

          </div> 

        </div>*/}
      </div>
    </>
  )
})

export default OrderOfStudents;
