import { Button, Checkbox, CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { CHART_WIDTH } from "../../constants/style";
import useStores from "../../hooks/use-stores";
import { WrittenFeedbackType } from "../../stores/academic-store";
import StudentTasksAdvancedSearch from "./student-tasks-advanced";
import IESBar from "../charts/iesBar";
import IESStackedVBar from "../charts/iesStackedVBar";
import AdvanceSearchModal from "../advance-search-modal";

const CURRENT_CHART_HEIGHT = "300PX";

const FeedbackAnalysis = observer(() => {
  const { academicStore, filterStore } = useStores();
  const [showAdvanceSearch, setShowAdvanceSearch] = useState<boolean>(false);

  const [byMarkingDaysLabel, setByMarkingDaysLabel] = useState<any>([]);
  const [byMarkingDaysAmount, setByMarkingDaysAmount] = useState<any>([]);
  const [byDueDateMarkingDateLabel, setByDueDateMarkingDateLabel] = useState<any>([]);
  const [byDueDateMarkingDateAmount, setByDueDateMarkingDateAmount] = useState<any>([]);
  const [perStudentByClassLabel, setPerStudentByClassLabel] = useState<any>([]);
  const [perStudentByClassAmount, setPerStudentByClassAmount] = useState<any>([]);
  const [nonSubmissionByTeacherLabel, setNonSubmissionByTeacherLabel] = useState<any>([]);
  const [nonSubmissionByTeacherAmount, setNonSubmissionByTeacherAmount] = useState<any>([]);
  const [writtenFeedbackCat, setWrittenFeedbackCat] = useState<any>([
    WrittenFeedbackType.marking, WrittenFeedbackType.file, WrittenFeedbackType.rubric
  ]);
  const [writtenFeedbackLabel, setWrittenFeedbackLabel] = useState<any>([]);
  const [writtenFeedbackAmount, setWrittenFeedbackAmount] = useState<any>([]);
  const [uidCount, setUidCount] = useState<number>(0);
  const [feedbackTypeCount, setFeedbackTypeCount] = useState<number>(0);
  const [checkedTeacherList, setCheckedTeacherList] = useState<any>([]);
  const [activeFunction, setActiveFunction] = useState<any>([]);

  useEffect(() => {
    if (academicStore.feedbackByMarkingDaysList.length > 0) {
      var tempLabel = [];
      var tempAmount = [];
      academicStore.feedbackByMarkingDaysList.map((feedback) => {
        tempLabel.push(feedback.label);
        tempAmount.push(+feedback.amountString);
      });
      setByMarkingDaysLabel(tempLabel);
      setByMarkingDaysAmount(tempAmount);
    }
  }, [academicStore.feedbackByMarkingDaysList]);

  useEffect(() => {
    if (academicStore.feedbackByDueDateMarkingDateList.length > 0) {
      var tempLabel = [];
      var tempAmount = [];
      academicStore.feedbackByDueDateMarkingDateList.map((feedback) => {
        tempLabel.push(feedback.label);
        tempAmount.push(+feedback.amountString);
      });
      setByDueDateMarkingDateLabel(tempLabel);
      setByDueDateMarkingDateAmount(tempAmount);
    }
  }, [academicStore.feedbackByDueDateMarkingDateList]);

  useEffect(() => {
    if (academicStore.feedbackPerStudentByClass.length > 0) {
      var tempLabel = [];
      var tempAmount = [];
      academicStore.feedbackPerStudentByClass.map((feedback) => {
        tempLabel.push(feedback.label);
        tempAmount.push(+feedback.amountString);
      });
      setPerStudentByClassLabel(tempLabel);
      setPerStudentByClassAmount(tempAmount);
    }
  }, [academicStore.feedbackPerStudentByClass]);

  useEffect(() => {
    if (academicStore.feedbackTableList.length > 0) {
      var countUid = 0;
      var countFeedbackType = 0;
      academicStore.feedbackTableList.map((feedback) => {
        countUid += feedback.countOfUid;
        countFeedbackType += feedback.anyFeedbackType;
      });

      setUidCount(countUid);
      setFeedbackTypeCount(countFeedbackType);
    }
  }, [academicStore.feedbackTableList]);

  useEffect(() => {
    if (academicStore.nonSubmissionByTeacherList.length > 0) {
      var tempLabel = [];
      var tempAmount = [];
      academicStore.nonSubmissionByTeacherList.map((feedback) => {
        tempLabel.push(feedback.label);
        tempAmount.push(feedback.amount);
      });

      setNonSubmissionByTeacherLabel(tempLabel);
      setNonSubmissionByTeacherAmount(tempAmount);
    }
  }, [academicStore.nonSubmissionByTeacherList]);

  useEffect(() => {
    if (academicStore.writtenFeedbackList.length > 0) {
      var tempLabel = [];
      var tempMarkingValue = [];
      var tempFileValue = [];
      var tempRubricValue = [];
      academicStore.writtenFeedbackList.map((feedback) => {
        tempLabel.push(feedback.category);
        feedback.detailData.map((data) => {
          if (data.label == WrittenFeedbackType.marking)
            tempMarkingValue.push(data.amount);
          else if (data.label == WrittenFeedbackType.file)
            tempFileValue.push(data.amount);
          else if (data.label == WrittenFeedbackType.rubric)
            tempRubricValue.push(data.amount);
        })
      });

      setWrittenFeedbackLabel(tempLabel);
      setWrittenFeedbackAmount([tempMarkingValue, tempFileValue, tempRubricValue]);
    }
  }, [academicStore.writtenFeedbackList]);
  
  const onApplyAdvanceSearch = (year, semester, yearLevel, classCodeList, domainList) => {

    var finalClassList;
    // if not display class codes in advance search modal, then assume class code is 'All'
    if (classCodeList.length == 1 && classCodeList[0] == "All") {
      var tempList = [];
      for (var c of filterStore.classList) {
        tempList.push(c.homeroomCode);
      }
      finalClassList = tempList;
    }
    else
      finalClassList = classCodeList;

    var finalDomainList = [];
    if (domainList.length == 1 && domainList[0] == "All") {
      for (var dl of filterStore.domainList) {
        finalDomainList.push(dl.domainDescription);
      }
    } else {
      for (var d of domainList) {
        for (var dl of filterStore.domainList) {
          if (+d == dl.id) {
            finalDomainList.push(dl.domainDescription);
            break;
          }
        }
      }
    }

    if (!semester && semester == 0) return;
    let reqData =
    {
      semesterId: semester,
      homeRoomList: finalClassList,
      domainDescriptionList: finalDomainList,
      subjectClassIdList: []  // All subjects
    };


    academicStore.getSubmissionAnalysisData(reqData);
    academicStore.getFeedbackAnalysisData(reqData);
    setCheckedTeacherList([])
  }

  const handleTeacherChecks = (event: React.ChangeEvent<{ value: unknown }>) => {
    let value = event.target.value as string;
    let temp = checkedTeacherList;

    // If the all is check now, then it should untick all other checkbox 
    if (value == "All" && !temp.includes("All")) {
      temp = ["All"];
    } else {
      // If other checkboxs are ticked, the all checkbox should be unticked
      if (temp.includes(value)) {
        if (temp.length == 1) {
          return;
        }
        let index = temp.indexOf(value);
        temp.splice(index, 1);
      } else {
        if (value !== "All" && temp.includes("All")) {
          let indexOfAll = temp.indexOf("All");
          temp.splice(indexOfAll, 1);
        }
        temp.push(value);

      }
    }
    setActiveFunction([]);
    setCheckedTeacherList(temp);
    academicStore.setAdvancedSelectedTeacherList(temp);
  }

  return (
    <div>
      <div className="mx-2 mt-2 pl-4 bg-barPrimary text-white grid grid-cols-2">
        <div className="py-2">Feedback Analysis</div>
        <div className="text-right">
          <Button
            className="bg-buttonPrimary text-white p-2"
            component="span"
            onClick={() => setShowAdvanceSearch(true)}
          >
            Advanced Search
          </Button>
        </div>
      </div>
      <div className="mx-2 mb-2 pl-4 py-2 bg-white">
        <div className="my-2 grid grid-cols-2 gap-2">
          <div>
            <p className="w-full text-center text-xs text-blue-600">
              Average of Submission to Marking Days by Class Teacher
            </p>

            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: byMarkingDaysLabel.length > 0 ? byMarkingDaysLabel : ["No Result"],
                      values: byMarkingDaysAmount.length > 0 ? byMarkingDaysAmount : [0]
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }
          </div>
          <div>
            <p className="w-full text-center text-xs text-blue-600">
              Average of Days Between Due Date and Marking Date by Class Teacher
            </p>

            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: byDueDateMarkingDateLabel.length > 0 ? byDueDateMarkingDateLabel : ["No Result"],
                      values: byDueDateMarkingDateAmount.length > 0 ? byDueDateMarkingDateAmount : [0]
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }

          </div>

        </div>
      </div>

      <div className="mx-2 mb-2 pl-4 py-2 bg-white">

        <div className="my-2 grid grid-cols-2 gap-2">
          <div>
            <p className="w-full text-center text-xs text-blue-600">
              Average Feedback Per Student by Class Teacher
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: perStudentByClassLabel.length > 0 ? perStudentByClassLabel : ["No Result"],
                      values: perStudentByClassAmount.length > 0 ? perStudentByClassAmount : [0]
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }
          </div>
          <div className="px-2">
            <p className="w-full text-center text-xs text-blue-600">
              Subject with No Feedback yet by Class Teacher
            </p>
            <div className="overflow-auto" style={{ maxHeight: CURRENT_CHART_HEIGHT }}>
              {
                academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <table className="mt-6 w-full">
                    <tr className="border-t border-b">
                      <td className="py-2 text-xs">ClassCode</td>
                      <td className="py-2 text-xs">ClassTeacher</td>
                      <td className="py-2 text-xs">DomainDescription</td>
                      <td className="py-2 text-xs text-right">AnyFeedbackType</td>
                      <td className="py-2 text-xs text-right">Count of UID</td>
                    </tr>
                    {
                      academicStore.feedbackTableList.map((record, index) => (
                        <tr className="border-t border-b" key={index}>
                          <td className="pr-2 text-xs">{record.classCode}</td>
                          <td className="pr-2 text-xs">{record.classTeacher}</td>
                          <td className="pr-2 text-xs">{record.domainDescription}</td>
                          <td className="pr-2 text-xs text-right">{record.anyFeedbackType}</td>
                          <td className="pr-2 text-xs text-right">{record.countOfUid}</td>
                        </tr>
                      ))
                    }
                    <tr className="border-t border-b">
                      <td className="pr-2 text-xs font-bold">Total</td>
                      <td className="pr-2 text-xs"></td>
                      <td className="pr-2 text-xs"></td>
                      <td className="pr-2 text-xs text-right">{feedbackTypeCount}</td>
                      <td className="pr-2 text-xs text-right">{uidCount}</td>
                    </tr>
                  </table>
              }
            </div>

          </div>

        </div>
      </div>

      <div className="mx-2 mb-2 pl-4 py-2 bg-white">

        <div className="my-2 grid grid-cols-2">
          <div>
            <p className="w-full text-center text-xs text-blue-600">
              Tasks listed in Simon/PAM as unsubmitted with due dates in selected range by Class Teacher
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: nonSubmissionByTeacherLabel.length > 0 ? nonSubmissionByTeacherLabel : ["No Result"],
                      values: nonSubmissionByTeacherAmount.length > 0 ? nonSubmissionByTeacherAmount : [0]
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }
          </div>
          <div>
            <p className="w-full text-center text-xs text-blue-600 mb-4">
              Total Written Feedback by Class Teacher
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESStackedVBar
                  dataAndSetting={{
                    data: {
                      names: writtenFeedbackLabel.length > 0 ? writtenFeedbackLabel : ["No Result"],
                      legends: writtenFeedbackCat,
                      values: writtenFeedbackAmount.length > 0 ? writtenFeedbackAmount : [[0], [0], [0]],
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                    xAxisLabelRotate: 20,
                  }}
                />
            }
          </div>

        </div>
      </div>
      <AdvanceSearchModal
        showModal={showAdvanceSearch}
        closeModal={() => setShowAdvanceSearch(false)}
        showYearLevelBar={true}
        showAcademicYear={true}
        showSemester={true}
        showYearLevel={true}
        showHomeClass={true}
        otherFilter={["domain"]}
        className="w-full"
        onApplyFilter={onApplyAdvanceSearch}
      >
        {
          academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            academicStore.resultTeacherList.length > 0 ?
              <div className="grid grid-cols-4">
                <div className="border-r-4 border-barPrimary overflow-auto" style={{ maxHeight: '100vh' }}>
                  <div className="bg-barPrimary p-2 text-white mb-4 w-full">
                    Selected Teachers
                  </div>
                  {
                    academicStore.resultTeacherList.map((teacher, index) => (
                      <div>
                        <Checkbox
                          key={`sub_teacher_list_${index}`}
                          value={teacher}
                          onChange={handleTeacherChecks}
                          checked={checkedTeacherList.includes(teacher) ? true : false}
                        /> {teacher}
                      </div>
                    ))
                  }
                </div>
                <div className="col-span-3">
                  <StudentTasksAdvancedSearch />
                </div>
              </div>
              : <StudentTasksAdvancedSearch />
        }
      </AdvanceSearchModal>
    </div>
  )
})

export default FeedbackAnalysis;