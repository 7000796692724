import React, { useEffect, useState } from 'react';
import { StoreProvider } from "../../stores/store-context";
import { Tabs, Tab } from "@material-ui/core";
import Layout from "../../components/shared-layout";
import TabPanel from '../../components/tab-panel';
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';

import FeedbackAnalysis from '../../components/student-tasks/feedback-analysis';
import SubmissionAnalysis from '../../components/student-tasks/submission-analysis';
import CohortAnalysis from '../../components/student-tasks/cohort-analysis';
import OrderOfStudents from '../../components/student-tasks/order-of-students';

function a11y1Props(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary' : 'text-black'} `
  };
}

function a11y2Props(index, isActive) {
  return {
    id: `simple-tab-2-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary' : 'text-black'} `
  };
}

const AcademicsPage = observer(() => {

  const { academicStore, filterStore } = useStores();

  const [value, setValue] = React.useState(0);
  const [innerValue, setInnerValue] = React.useState(0);

  const handleOuterChange = (event, newValue) => {
    setValue(newValue);
    setInnerValue(0);
  };

  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue);
  };

  useEffect(() => {
    // get teacher list
    filterStore.getTeacherList(30);
  }, [])
  useEffect(() => {
    var reqData = {
      semesterId: 33,
      homeRoomList: ['11A'],
      domainDescriptionList: ["English"],
      subjectClassIdList: []
    }
    console.log(reqData);

    if (value === 0 && innerValue === 0) {
      academicStore.getFeedbackAnalysisData(reqData);
    }
    else if (value === 0 && innerValue === 1) {
      academicStore.getSubmissionAnalysisData(reqData);
    }
    else if (value === 1 && innerValue === 0) {
      academicStore.getPerformanceAnalysisData(reqData);
    }
    else if (value === 1 && innerValue === 1) {
      academicStore.getAveragePercentageByStudent(reqData);
    }
  }, [innerValue, value]);

  const onApplyFilter = (req: any) => {

    var tempSubjectClassIdList = []
    if (req.subject !== 0) {
      if (req.subjectClass !== 0) {
        tempSubjectClassIdList.push(req.subjectClass);
      }
      else {
        filterStore.subjectClassList.map(e => {
          tempSubjectClassIdList.push(e.id);
        });
      }
    }

    var reqData = {
      semesterId: req.semester,
      homeRoomList: req.classCode,
      domainDescriptionList: req.domainDescriptionList,
      subjectClassIdList: tempSubjectClassIdList
    }

    if (value === 0 && innerValue === 0) {
      academicStore.getFeedbackAnalysisData(reqData);
    }
    else if (value === 0 && innerValue === 1) {
      academicStore.getSubmissionAnalysisData(reqData);
    }
    else if (value === 1 && innerValue === 0) {
      academicStore.getPerformanceAnalysisData(reqData);
    }
    else if (value === 1 && innerValue === 1) {
      academicStore.getAveragePercentageByStudent(reqData);
    }

  }
  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={true}
          showYearSemTerm={true}
          showClassCode={true}
          showDomain={true}
          showYearRange={false}
          showStaffList={false}
          defaultYear={"2018"}
          onApplyFilter={onApplyFilter}
        >
          <div className="col-span-4 flex flex-col">
            <div className="mx-2 mt-2 pl-4 py-2 bg-white border-b-2 border-barPrimary">
              <Tabs value={value}
                onChange={handleOuterChange}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }} >
                <Tab label="Task Submission Analysis" {...a11y1Props(0, 0 === value)} />
                <Tab label="Task Performance Analysis" {...a11y1Props(1, 1 === value)} />
              </Tabs>
            </div>

            <TabPanel value={value} paddingValue={0} index={0}>
              {/* Task Submission Analysis */}
              <div className="mx-2 py-2 bg-white">
                <Tabs value={innerValue}
                  onChange={handleInnerChange}
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }} >
                  <Tab label="Feedback Analysis" {...a11y2Props(0, 0 === innerValue)} />
                  <Tab label="Submission Analysis" {...a11y2Props(1, 1 === innerValue)} />

                </Tabs>
              </div>

              <TabPanel value={innerValue} paddingValue={0} index={0}>
                {/* Feedback Analysis */}

                <FeedbackAnalysis />
              </TabPanel>
              <TabPanel value={innerValue} paddingValue={0} index={1}>
                {/* Submission Analysis */}

                <SubmissionAnalysis />
              </TabPanel>
            </TabPanel>

            <TabPanel value={value} paddingValue={0} index={1}>
              <div className="mx-2 py-2 bg-white">
                <Tabs value={innerValue}
                  onChange={handleInnerChange}
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }} >
                  <Tab label="Cohort Analysis" {...a11y2Props(0, 0 === innerValue)} />
                  <Tab label="Order of Students in Year Level" {...a11y2Props(1, 1 === innerValue)} />
                </Tabs>
              </div>

              <TabPanel value={innerValue} paddingValue={0} index={0}>

                <CohortAnalysis />
              </TabPanel>
              <TabPanel value={innerValue} paddingValue={0} index={1}>

                <OrderOfStudents />
              </TabPanel>
            </TabPanel>

          </div>
        </Layout>
      }
    </>
  )
})

export default AcademicsPage;