import React, { useState, useEffect } from "react";
import useStores from "../../hooks/use-stores";
import { CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react";
import IESBoxPlot from "../charts/iesBoxplot";
import IESFrequencyPlot from "../charts/iesFrequencyPlot";


const CohortAnalysis = observer(() => {

  const { academicStore, filterStore } = useStores();

  const [studentPerformanceDensity, setStudentPerformanceDensity] = useState([]);
  const [studentMarksClassCode, setStudentMarksClassCode] = useState({
    boxData: [
      [23, 45, 34, 54, 32, 43, 52, 28, 54, 15, 17, 13, 31],
      [23, 34, 45, 56, 67, 78, 23, 34, 53, 23, 54, 62, 56],
      [23, 34, 45, 56, 23, 34, 45, 56, 67, 78, 10, 67, 78,],
      [23, 34, 45, 56, 67, 78, 23, 24, 53, 73, 54, 62, 56],
    ],
    category: ['C1', 'C2', 'C3', 'C4']
  })
  const [studentMarksYear, setStudentMarksYear] = useState({
    boxData: [
      [23, 45, 34, 54, 32, 43, 52, 28, 54, 15, 17, 13, 31],
      [23, 34, 45, 56, 67, 78, 23, 34, 53, 23, 54, 62, 56],
      [23, 34, 45, 56, 23, 34, 45, 56, 67, 78, 10, 67, 78,],
      [23, 34, 45, 56, 67, 78, 23, 24, 53, 73, 54, 62, 56],
    ],
    category: ['2018', '2019', '2020', '2021']
  })
  const [studentMarksDomain, setStudentMarksDomain] = useState({
    boxData: [
      [23, 45, 34, 54, 32, 43, 52, 28, 54, 15, 77, 73, 31],
      [23, 24, 45, 56, 67, 78, 23, 34, 53, 23, 54, 62, 56],
      [23, 34, 45, 56, 33, 34, 45, 56, 67, 78, 80, 67, 78,],
      [23, 34, 45, 56, 67, 78, 23, 24, 53, 73, 54, 42, 96],
      [23, 24, 45, 56, 67, 78, 23, 34, 53, 23, 54, 67, 56],
    ],
    category: ['C1', 'C2', 'C3', 'C4', 'Y7']
  })

  useEffect(() => {
    if (academicStore.performanceAnalysisData !== null) {
      if ('performanceOverView' in academicStore.performanceAnalysisData) {
        setStudentPerformanceDensity(academicStore.performanceAnalysisData.performanceOverView.doubleList);
      }
      if ('marksByClassCode' in academicStore.performanceAnalysisData) {
        let tempboxData = [];
        let tempCategory = [];
        academicStore.performanceAnalysisData.marksByClassCode.map((d) => {
          tempCategory.push(d.category)
          tempboxData.push(d.doubleList)
        })
        setStudentMarksClassCode({
          boxData: tempboxData,
          category: tempCategory
        });
      }
      if ('marksByYearLevel' in academicStore.performanceAnalysisData) {
        let tempboxData = [];
        let tempCategory = [];
        academicStore.performanceAnalysisData.marksByYearLevel.map((d) => {
          tempCategory.push(d.category)
          tempboxData.push(d.doubleList)
        })
        setStudentMarksYear({
          boxData: tempboxData,
          category: tempCategory
        });
      }
      if ('marksByDomain' in academicStore.performanceAnalysisData) {
        let tempboxData = [];
        let tempCategory = [];
        academicStore.performanceAnalysisData.marksByDomain.map((d) => {
          tempCategory.push(d.category)
          tempboxData.push(d.doubleList)
        })
        setStudentMarksDomain({
          boxData: tempboxData,
          category: tempCategory
        });
      }
    }
  }, [academicStore.performanceAnalysisData]);

  return (
    <div>
      <div className="mx-2 mt-2 pl-4 bg-barPrimary text-white grid grid-cols-2">
        <div className="py-2">Performance Analysis</div>
      </div>
      <div className="mx-2 mb-2 pl-4 py-2 bg-white">
        <p className="w-full text-center p-4 text-blue-600">
          Student Performance Overview (By Density)
        </p>
        {
          academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            <IESFrequencyPlot
              dataAndSetting={{
                data: studentPerformanceDensity.length > 0 ? studentPerformanceDensity : [0, 0, 1],
                height: '350px',
                width: '100%',
                barColor: '#a03037'
              }}
            />
        }
        <p className="w-full text-center p-4 text-blue-600">
          Student Marks across Domains by class code (if Domain is selected)
        </p>
        {
          academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            <IESBoxPlot
              dataAndSetting={{
                data: studentMarksClassCode,
                height: '350px',
                width: '100%',
                xAxisLabelRotate: 90,
              }}
            />
        }
        <div className="my-2 grid grid-cols-2">
          <div>
            <p className="w-full text-center text-blue-600">
              Student Marks across Domains by Year Level (if Domain is selected)
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBoxPlot
                  dataAndSetting={{
                    data: studentMarksYear,
                    height: '350px',
                    width: '400px',
                    xAxisLabelRotate: 0,
                  }}
                />
            }
          </div>
          <div>
            <p className="w-full text-center text-blue-600">
              Student Marks across Domains by Domain
            </p>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBoxPlot
                  dataAndSetting={{
                    data: studentMarksDomain,
                    height: '350px',
                    width: '400px',
                    xAxisLabelRotate: 90,
                  }}
                />
            }
          </div>

        </div>
      </div>
    </div>
  )
})

export default CohortAnalysis;